<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'gallery'}"/>
        <div class="container has-text-centered mb-3">
          <div class="py-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ $store.state.uiMods.gallery }}
          </div>
          <div class="is-size-7 has-text-grey mb-5 px-4">
            {{ $store.state.uiMods.gallerySubtext ?
            $store.state.uiMods.gallerySubtext :
            `Your uploaded photos & videos will appear here. They will be auto-deleted some time after the ${$store.state.uiMods.game.toLowerCase()} ends.`}}
          </div>
        </div>
        <div v-if="!adventureTeam" class="container has-text-centered mb-3 px-5">
          <div class="title-skeleton-fullwidth loading mt-3"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
        </div>
        <div v-if="adventureTeam && media.length > 0" class="container has-text-centered mb-3">
          <div v-for="(x, index) in media" :key="index" class="mb-4">
            <video class="has-rounded-corners" v-if="['.MP4', '.MOV'].includes(x.ext.toUpperCase())" width="320" height="240" controls>
              <source :src="x.value" type="video/mp4">
              <source :src="x.value" type="video/ogg">
              Your browser does not support the video tag.
            </video>
            <img v-else class="has-rounded-corners" :src="x.value" />
            <div class="container is-flex is-flex-direction-row-reverse">
              <button class="button is-primary is-rounded is-small is-pulled-right" @click="downloadFromUrl(x)">
                <i class="fas fa-download mr-2"></i>
                Download
              </button>
            </div>
          </div>
        </div>
        <div class="container has-text-centered my-5 pb-4">
          <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded">
            <i class="fas fa-clipboard mr-2"></i>
            Back to {{ p(adventureTeam, 'task', 2) }} page
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import ViewsStat from '@/components/ViewsStat.vue'

export default {
  name: 'Gallery',
  components: {
    ViewsStat,
    CardNavigation
  },
  data() {
    return {
    }
  },
  methods: {
    downloadFromUrl (file) {
      const url = file.value
      const filename = new Date().getTime().toString() + file.ext
      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          // the filename you want
          a.download = filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => alert('Too many downloads at the same time.'))
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    media() {
      if (
        this.adventureTeam &&
        this.adventureTeam.answerLog &&
        this.adventureTeam.answerLog.length > 0
      ) {
        return this.adventureTeam.answerLog
        .filter(x => {
          return x.answerObj.ext
        })
        .map(x => {
          return x.answerObj
        })
        .reverse()
      } else {
        return []
      }
    },
    teamCode() {
      return this.$store.state.teamCode
    }
  },
  mounted() {
    // if(
    //   this.adventureTeam.hideLeaderboard &&
    //   !(
    //     this.adventureTeam.showLeaderboardAtCompletion &&
    //     this.adventureTeam.completedAt
    //   )
    // ){
    //   this.$router.push({ name: 'Stage' })
    // }
    // this.$store.dispatch('enterPage', {
    //   pageName: 'leaderboard'
    // })
  }
}
</script>
